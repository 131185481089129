
<template>
  <v-card color="#3e4c55" class="mt-2 mr-5 ml-2">
    <v-row align="end" class="fill-height">
      <v-col class="pt-0">
        <v-list-item color="rgba(0, 0, 0, .4)" dark>
          <v-list-item-content>
            <v-list-item-title class="title">{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row class="pl-2 pr-2">
          <v-col col="12" md="6" sm="6">
            <v-btn small block color="#008483" dark
              >Modules: {{ Number(modules) | numberFormatter }}</v-btn
            >
          </v-col>
          <v-col col="12" md="6" sm="6">
            <v-btn small block color="#008483" dark
              >Submodules:{{ Number(sub_modules) | numberFormatter }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-actions></v-card-actions>

    <v-bottom-navigation>
      <v-spacer></v-spacer>
      <v-btn
        text
        v-if="checkWritePermission($modules.settings.roles.slug)"
        @click="$emit('edit', id)"
      >
        <span>Edit</span>
        <v-icon medium>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="deleteRole"
        text
        v-if="venue_id && checkDeletePermission($modules.settings.roles.slug)"
      >
        <span>{{ status_id == 1 ? "Delete" : "Restore" }}</span>
        <v-icon>
          {{ status_id == 1 ? "mdi-delete" : "mdi-delete-restore" }}
        </v-icon>
      </v-btn>
      <v-spacer v-if="venue_id"></v-spacer>
    </v-bottom-navigation>
  </v-card>
</template>
<script>
export default {
  props: {
    id: { type: Number, default: 0 },
    status_id: { type: Number, default: 1 },
    venue_id: { type: Number, default: null },
    title: { type: String, default: "" },
    modules: { type: Number, default: 0 },
    sub_modules: { type: Number, default: 0 },
  },

  methods: {
    deleteRole() {
      this.$emit("delete", {
        id: this.id,
        status: this.status_id == 1 ? "delete" : "restore",
      });
    },
  },
};
</script>

<style scoped></style>
