<template>
  <v-container>
    <v-row>
      <v-col></v-col>
      <v-spacer></v-spacer>
      <v-col lg="4" sm="4" style="text-align: center">
        <v-btn-toggle
          borderless
          class="button_navigation"
          v-model="status"
          mandatory
          @change="getUserRolesList"
        >
          <v-btn value="1">Active</v-btn>

          <v-btn value="2">InActive</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right" lg="2" sm="4">
        <v-btn
          v-if="checkWritePermission($modules.settings.roles.slug)"
          @click="addRole"
          class="mr-6 teal-color"
          dark
          height="45"
        >
          Role
          <v-icon right dark>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(role, i) in roles">
        <v-col :key="i" lg="4">
          <role-card
            v-bind="role"
            @edit="showRoleDetails"
            @delete="deleteRole"
          ></role-card>
        </v-col>
      </template>
    </v-row>
    <v-dialog v-model="role_dialog" max-width="650" scrollable>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="headline"
            >{{ editFlag ? "Edit" : "Add" }} Role</v-card-title
          >
          <v-card-text class="form_bg pt-4">
            <v-row>
              <v-col md="12">
                <v-text-field
                  label="Role name"
                  hint="Role name"
                  persistent-hint
                  v-model="role.title"
                  :readonly="
                    !(typeof role.venue_id == 'undefined') &&
                    role.venue_id == null
                  "
                  outlined
                  background-color="#fff"
                ></v-text-field>
              </v-col>
              <div class="titles">Module Selected</div>
              <v-col md="12">
                <v-row>
                  <v-col
                    md="3"
                    v-for="(modules, mIndex) in modules"
                    :key="`m_${mIndex}`"
                    style="margin-top: -30px"
                  >
                    <v-checkbox
                      v-model="modulesSelected"
                      :value="modules.module_id"
                      class="mx-2"
                      :readonly="modules.module_name == userProfileModule"
                      :label="`${modules.module_name}`"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <div class="titles">Role Permissions</div>
              <v-col md="12">
                <v-expansion-panels>
                  <template v-for="(modules, mIndex) in role.permissions">
                    <v-expansion-panel
                      v-if="
                        modules.module_id != null &&
                        checkModuleExist(modules.module_id)
                      "
                      :key="mIndex"
                    >
                      <v-expansion-panel-header>{{
                        modules.module_name
                      }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <table class="permission_table">
                          <thead>
                            <tr>
                              <th>Module</th>
                              <th
                                class="pointer"
                                @click="checkAllSubModules('read', mIndex)"
                              >
                                Read
                                <v-icon
                                  :color="
                                    checkSubModulesPermission(
                                      'allow_read',
                                      mIndex
                                    ) == 1
                                      ? 'success'
                                      : checkSubModulesPermission(
                                          'allow_read',
                                          mIndex
                                        ) == 0
                                      ? 'error'
                                      : 'warning'
                                  "
                                  right
                                >
                                  {{
                                    checkSubModulesPermission(
                                      "allow_read",
                                      mIndex
                                    ) == 1
                                      ? "mdi-check"
                                      : checkSubModulesPermission(
                                          "allow_read",
                                          mIndex
                                        ) == 0
                                      ? "mdi-close"
                                      : "mdi-minus"
                                  }}</v-icon
                                >
                              </th>
                              <th
                                class="pointer"
                                @click="checkAllSubModules('write', mIndex)"
                              >
                                Write
                                <v-icon
                                  :color="
                                    checkSubModulesPermission(
                                      'allow_write',
                                      mIndex
                                    ) == 1
                                      ? 'success'
                                      : checkSubModulesPermission(
                                          'allow_write',
                                          mIndex
                                        ) == 0
                                      ? 'error'
                                      : 'warning'
                                  "
                                  right
                                >
                                  {{
                                    checkSubModulesPermission(
                                      "allow_write",
                                      mIndex
                                    ) == 1
                                      ? "mdi-check"
                                      : checkSubModulesPermission(
                                          "allow_write",
                                          mIndex
                                        ) == 0
                                      ? "mdi-close"
                                      : "mdi-minus"
                                  }}</v-icon
                                >
                              </th>
                              <th
                                class="pointer"
                                @click="checkAllSubModules('delete', mIndex)"
                              >
                                Delete
                                <v-icon
                                  :color="
                                    checkSubModulesPermission(
                                      'allow_delete',
                                      mIndex
                                    ) == 1
                                      ? 'success'
                                      : checkSubModulesPermission(
                                          'allow_delete',
                                          mIndex
                                        ) == 0
                                      ? 'error'
                                      : 'warning'
                                  "
                                  right
                                >
                                  {{
                                    checkSubModulesPermission(
                                      "allow_delete",
                                      mIndex
                                    ) == 1
                                      ? "mdi-check"
                                      : checkSubModulesPermission(
                                          "allow_delete",
                                          mIndex
                                        ) == 0
                                      ? "mdi-close"
                                      : "mdi-minus"
                                  }}</v-icon
                                >
                              </th>
                              <th
                                class="pointer"
                                @click="checkAllSubModules('export', mIndex)"
                              >
                                Export
                                <v-icon
                                  :color="
                                    checkSubModulesPermission(
                                      'allow_export',
                                      mIndex
                                    ) == 1
                                      ? 'success'
                                      : checkSubModulesPermission(
                                          'allow_export',
                                          mIndex
                                        ) == 0
                                      ? 'error'
                                      : 'warning'
                                  "
                                  right
                                >
                                  {{
                                    checkSubModulesPermission(
                                      "allow_export",
                                      mIndex
                                    ) == 1
                                      ? "mdi-check"
                                      : checkSubModulesPermission(
                                          "allow_export",
                                          mIndex
                                        ) == 0
                                      ? "mdi-close"
                                      : "mdi-minus"
                                  }}</v-icon
                                >
                              </th>
                              <th
                                class="pointer"
                                @click="checkAllSubModules('backfill', mIndex)"
                              >
                                Backfill
                                <v-icon
                                  :color="
                                    checkSubModulesPermission(
                                      'allow_backfill',
                                      mIndex
                                    ) == 1
                                      ? 'success'
                                      : checkSubModulesPermission(
                                          'allow_backfill',
                                          mIndex
                                        ) == 0
                                      ? 'error'
                                      : 'warning'
                                  "
                                  right
                                >
                                  {{
                                    checkSubModulesPermission(
                                      "allow_backfill",
                                      mIndex
                                    ) == 1
                                      ? "mdi-check"
                                      : checkSubModulesPermission(
                                          "allow_backfill",
                                          mIndex
                                        ) == 0
                                      ? "mdi-close"
                                      : "mdi-minus"
                                  }}</v-icon
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(permission, index) in modules.sub_modules"
                              :key="permission.sub_module_id"
                            >
                              <td>{{ permission.sub_module_name }}</td>
                              <td>
                                <v-btn
                                  icon
                                  :color="
                                    permission.allow_read == 1
                                      ? 'success'
                                      : 'error'
                                  "
                                  @click="
                                    changeModulePermission(
                                      'read',
                                      mIndex,
                                      index
                                    )
                                  "
                                >
                                  <v-icon>
                                    {{
                                      permission.allow_read == 1
                                        ? "mdi-check"
                                        : "mdi-close"
                                    }}
                                  </v-icon>
                                </v-btn>
                              </td>
                              <td>
                                <v-btn
                                  icon
                                  :color="
                                    permission.allow_write == 1
                                      ? 'success'
                                      : 'error'
                                  "
                                  @click="
                                    changeModulePermission(
                                      'write',
                                      mIndex,
                                      index
                                    )
                                  "
                                >
                                  <v-icon>
                                    {{
                                      permission.allow_write == 1
                                        ? "mdi-check"
                                        : "mdi-close"
                                    }}
                                  </v-icon>
                                </v-btn>
                              </td>
                              <td>
                                <v-btn
                                  icon
                                  :color="
                                    permission.allow_delete == 1
                                      ? 'success'
                                      : 'error'
                                  "
                                  @click="
                                    changeModulePermission(
                                      'delete',
                                      mIndex,
                                      index
                                    )
                                  "
                                >
                                  <v-icon>
                                    {{
                                      permission.allow_delete == 1
                                        ? "mdi-check"
                                        : "mdi-close"
                                    }}
                                  </v-icon>
                                </v-btn>
                              </td>
                              <td>
                                <v-btn
                                  icon
                                  :color="
                                    permission.allow_export == 1
                                      ? 'success'
                                      : 'error'
                                  "
                                  @click="
                                    changeModulePermission(
                                      'export',
                                      mIndex,
                                      index
                                    )
                                  "
                                >
                                  <v-icon>
                                    {{
                                      permission.allow_export == 1
                                        ? "mdi-check"
                                        : "mdi-close"
                                    }}
                                  </v-icon>
                                </v-btn>
                              </td>
                              <td>
                                <v-btn
                                  icon
                                  :color="
                                    permission.allow_backfill == 1
                                      ? 'success'
                                      : 'error'
                                  "
                                  @click="
                                    changeModulePermission(
                                      'backfill',
                                      mIndex,
                                      index
                                    )
                                  "
                                >
                                  <v-icon>
                                    {{
                                      permission.allow_backfill == 1
                                        ? "mdi-check"
                                        : "mdi-close"
                                    }}
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 white--text blue-color"
              text
              @click="role_dialog = false"
              >Close</v-btn
            >
            <v-btn
              class="ma-2 white--text teal-color"
              text
              @click="addUpdateRole"
              >{{ editFlag ? "Update" : "Add" }} Role</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import RoleCard from "@/components/Settings/RoleCard";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
export default {
  components: {
    RoleCard,
    ConfirmModel,
  },
  data() {
    return {
      roles: [],
      editFlag: false,
      role_dialog: false,
      modules: [],
      valid: false,
      status: 1,
      role: { permissions: [] },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      modulesSelected: [],
    };
  },
  computed: {
    userProfileModule() {
      return process.env.VUE_APP_USER_PROFILE_MODULE;
    },
  },
  mounted() {
    this.getVenueSubscriptions();
  },
  methods: {
    addRole() {
      this.role = {};
      this.editFlag = false;
      this.role.permissions = this.modules;
      this.modulesSelected = this.modules.map((item) => item.module_id);
      this.role_dialog = true;
    },
    checkModuleExist(moduleID) {
      let check = this.modulesSelected.find((item) => item == moduleID);
      return check != null ? true : false;
    },
    getUserRolesList() {
      this.$http
        .get(`venues/roles?status_id=${this.status}`)
        .then((response) => {
          if (response.status == 200) this.roles = response.data.data;
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getVenueSubscriptions() {
      this.$http
        .get("venues/profile/subscriptions")
        .then((response) => {
          if (response.status == 200) {
            this.modules = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeModulePermission(type, moduleIndex, subIndex) {
      let subModule = this.role.permissions[moduleIndex].sub_modules[subIndex];

      if (type == "read") {
        if (subModule.module_name == this.userProfileModule) {
          this.showError("Error! User basic read permission cannot be changed");
          return;
        }
        this.role.permissions[moduleIndex].sub_modules[subIndex].allow_read =
          subModule.allow_read == 1 ? 0 : 1;
        if (
          this.role.permissions[moduleIndex].sub_modules[subIndex].allow_read ==
          0
        ) {
          if (subModule.allow_write == 1) {
            this.role.permissions[moduleIndex].sub_modules[
              subIndex
            ].allow_write = 0;
          }
          if (subModule.allow_delete == 1) {
            this.role.permissions[moduleIndex].sub_modules[
              subIndex
            ].allow_delete = 0;
          }
          if (subModule.allow_export == 1) {
            this.role.permissions[moduleIndex].sub_modules[
              subIndex
            ].allow_export = 0;
          }
          if (subModule.allow_backfill == 1) {
            this.role.permissions[moduleIndex].sub_modules[
              subIndex
            ].allow_backfill = 0;
          }
        }
      } else if (type == "write") {
        this.role.permissions[moduleIndex].sub_modules[subIndex].allow_write =
          subModule.allow_write == 1 ? 0 : 1;
      } else if (type == "delete") {
        this.role.permissions[moduleIndex].sub_modules[subIndex].allow_delete =
          subModule.allow_delete == 1 ? 0 : 1;
      } else if (type == "export") {
        this.role.permissions[moduleIndex].sub_modules[subIndex].allow_export =
          subModule.allow_export == 1 ? 0 : 1;
      } else if (type == "backfill") {
        this.role.permissions[moduleIndex].sub_modules[
          subIndex
        ].allow_backfill = subModule.allow_backfill == 1 ? 0 : 1;
      }
      this.$forceUpdate();
    },
    checkAllSubModules(type, moduleIndex) {
      this.role.permissions[moduleIndex].sub_modules.forEach(
        (subModule, index) => {
          this.changeModulePermission(type, moduleIndex, index);
        }
      );
    },
    checkSubModulesPermission(type, moduleIndex) {
      let checkIndex = this.role.permissions[moduleIndex].sub_modules.findIndex(
        (item) => item[type] == 1
      );
      let crossIndex = this.role.permissions[moduleIndex].sub_modules.findIndex(
        (item) => item[type] == 0
      );
      if (checkIndex != -1 && crossIndex != -1) {
        return 2;
      } else if (checkIndex >= 0) {
        return 1;
      } else if (crossIndex >= 0) {
        return 0;
      }
    },
    showRoleDetails(id) {
      this.$http
        .get("venues/roles/" + id)
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.data;
            this.role.title =
              typeof data.title != "undefined" ? data.title : "";
            this.role.id = typeof data.id != "undefined" ? data.id : "";
            this.role.venue_id =
              typeof data.venue_id != "undefined" ? data.venue_id : "";
            this.modulesSelected = data.permissions.map(
              (item) => item.module_id
            );
            this.role.permissions = data.permissions;
            this.modules.forEach((permission) => {
              if (!this.checkModuleExist(permission.module_id)) {
                this.role.permissions.push(permission);
              }
            });
            this.role_dialog = true;
            this.editFlag = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addUpdateRole() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      this.role.role_permissions = [];
      this.role.permissions.forEach((permission) => {
        if (this.checkModuleExist(permission.module_id)) {
          this.role.role_permissions.push(...permission.sub_modules);
        }
      });
      // delete this.role.permissions;
      this.$http({
        method: this.role.id != null ? "put" : "post",
        url: `venues/roles${this.role.id != null ? "/" + this.role.id : ""}`,
        data: this.role,
      })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.role_dialog = false;
            this.showSuccess(response.data.message);
            this.hideLoader();
            this.getUserRolesList();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    deleteRole(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.status} this role?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.changeRoleStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    changeRoleStatus(id) {
      this.$http
        .delete("venues/roles/" + id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.getUserRolesList();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.permission_table {
  width: 100%;
  text-align: center !important;
}
.permission_table th {
  text-align: center !important;
}
.permission_table td,
.permission_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.permission_table tr {
  background-color: #f2f2f2;
}
.permission_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}

.permission_table tr:hover {
  background-color: #ddd;
}
.permission_table .header {
  background-color: rgb(209, 209, 209);
}

.permission_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
</style>